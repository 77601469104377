export const BASEURL = "https://www.backend.ssebowa.org/";
// export const BASEURL = "https://ssebowa1.onrender.com/";
export const BASEURL_Blog = "https://api.ssebowa.org/";
// export const BASEURL_Chat_Bot = "";
export const BASEURL_Chat_Bot = "https://chatapi.ssebowa.org/chatbot/";
export const BASEURL_Translation = "https://ssebowatra.onrender.com/translators/";
export const BASEURL_AI = "https://chatapi.ssebowa.org/"
export const ChromeExtensionURL = "https://chrome.google.com/webstore/detail/ssebowaorg-search-chat-an/ihobpjnnlchoeecjignabclimncghifh?hl=en-US"

export const TreesIncrementRate = 1.3
export const MealsIncrementRate = 1
export const PadsIncrementRate = 0.7
