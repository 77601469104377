import React, { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { FreeMode } from "swiper";
import { ColorRing } from "react-loader-spinner";

function SearchResultsVideos({ query }) {
  const [videoData, setVideoData] = useState(null);
  const [videoDataLoaded, setVideoDataLoaded] = useState(false);
  const sliderRef = useRef(null);

  useEffect(async() => {
    setVideoDataLoaded(false);
    await fetch(`https://chatapi.ssebowa.org/video_new?keyword=${query}`, {
      method: "post",
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("video data", data.video)
        setVideoData(data.video);
        setVideoDataLoaded(true);
      })
      .catch((error) => console.error(error));
  }, [query]);

  return (
    <div className="my-4 w-100 px-2 px-lg-3">
      <div className="position-relative">
        {videoData && (
          <div className="">
            <Swiper
              ref={sliderRef}
              grabCursor={true}
              height={325}
              cssMode={false}
              freeMode={{ sticky: false, enabled: true }}
              spaceBetween={15}
              slidesPerView={"auto"}
              breakpoints={{
                300: {
                  cssMode: true,
                },
                700: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
              }}
              pagination={false}
              modules={[FreeMode]}
              className="mySwiper"
            >
              {videoDataLoaded ? (
                Object.keys(videoData).map((videoInfo, index) => (
                  <SwiperSlide key={index}>
                    {" "}
                    <Card key={index} className="result-video-card">
                      <a
                        href={videoData[videoInfo].source_url}
                        style={{ textDecoration: "none" }}
                      >
                        <CardMedia
                          component="img"
                          image={videoData[videoInfo].thumpnail}
                          className="video-ele"
                          sx={{ width: "200px" }}
                        />
                      </a>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <CardContent
                          sx={{
                            flex: "flex",
                            textAlign: "left",
                          }}
                        >
                          <a
                            href={videoData[videoInfo].source_url}
                            style={{ textDecoration: "none" }}
                          >
                            <Typography
                              component="div"
                              variant="h5"
                              sx={{ color: "blue" }}
                              className="video-title"
                            >
                              {videoData[videoInfo].title?.length > 65
                                ? `${videoData[videoInfo].title.substring(
                                    0,
                                    65
                                  )}...`
                                : videoData[videoInfo].title}
                            </Typography>
                          </a>
                          <Typography
                            component="h6"
                            variant="h6"
                            sx={{ color: "text.secondary" }}
                            className="channel-name"
                          >
                            {videoData[videoInfo].channel_name}
                          </Typography>

                          <Typography
                            component="span"
                            variant="h6"
                            className="channel-platform"
                          >
                            {videoData[videoInfo].platform}
                            <span> </span>
                          </Typography>
                          <Typography
                            component="span"
                            variant="small"
                            sx={{ color: "info.main" }}
                            className="video-date"
                          >
                            <span> </span> {videoData[videoInfo].date}
                          </Typography>
                          <Typography
                            component="div"
                            variant="h6"
                            sx={{ color: "text.secondary" }}
                            className="video-views"
                          >
                            <span>views:</span> {videoData[videoInfo].views}
                          </Typography>
                        </CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            pl: 1,
                            pb: 1,
                          }}
                        >
                          <Typography></Typography>
                        </Box>
                      </Box>
                    </Card>
                  </SwiperSlide>
                ))
              ) : (
                <LoadingSpaceWithRings/>
              )}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchResultsVideos;
export const LoadingSpaceWithRings = () => {
  return (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <p>Loading...</p>
    </div>
  );
};
