import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NavScrollTop from "./components/nav-scroll-top";
import HomePage from "./pages/index";
import AboutPage from "./pages/about";
import GalleryPage from "./pages/gallery";
import TeamPage from "./pages/team";
import FaqPage from "./pages/faq";
import BlogPage from "./pages/blog";
import BlogDetailsPage from "./templates/blog-details";
import ContactPage from "./pages/contact";
import TermsAndConditions from "./pages/terms-and-conditions";
import PrivacyPolicy from "./pages/privacy-policy";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faBars, faHouse, faCircleInfo, faHistory, faListCheck, faPhone, faPeopleGroup, faImage, faUser, faStore, faBlog, faComment } from "@fortawesome/free-solid-svg-icons";
import "./assets/css/vendor/metropolis.css";
import "./assets/css/vendor/icofont.min.css";
import "./assets/css/vendor/font-awesome.css";
import "./assets/css/vendor/material-design-iconic.min.css";
import "./assets/css/plugins/animate.min.css";
import "./assets/scss/style.scss";
import SearchPage from "./pages/search";
import SearchBotPage from "./pages/search-bot";
import ErrorPage from "./pages/error";

const App = () => {
    return (
        <Router>
            <NavScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomePage}  />
                    <Route path={`${process.env.PUBLIC_URL + "/bot"}`} component={SearchBotPage} exact />
                    <Route path={`${process.env.PUBLIC_URL + "/search"}`} component={SearchPage} exact />
                    <Route path={`${process.env.PUBLIC_URL + "/about"}`} component={AboutPage} exact />
                    <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} component={GalleryPage} exact />
                    <Route path={`${process.env.PUBLIC_URL + "/team"}`} component={TeamPage} exact />
                    <Route path={`${process.env.PUBLIC_URL + "/faq"}`} component={FaqPage} exact />
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component={ContactPage} exact />
                    <Route path={`${process.env.PUBLIC_URL + "/projects"}`} component={BlogPage} exact />
                    <Route path={`${process.env.PUBLIC_URL + "/terms-and-conditions"}`} component={TermsAndConditions} exact />
                    <Route path={`${process.env.PUBLIC_URL + "/privacy-policy"}`} component={PrivacyPolicy} exact />
                    <Route path={`${process.env.PUBLIC_URL + "/project-details/:id"}`} component={BlogDetailsPage} exact />
                    <Route component={ErrorPage} />
                </Switch>
            </NavScrollTop>
        </Router>
    );
};

export default App;

library.add(fab, faBars, faHouse, faCircleInfo, faHistory, faListCheck, faPhone, faPeopleGroup, faImage, faUser, faStore, faBlog, faComment);
