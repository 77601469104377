import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import NavBarUpdated2 from "../components/NavBarUpdated/NavBarUpdated2";
import StickyChatBot from "../components/stickyChatBot";
import Footer from "../../src/components/FooterUpdated/Footer";
import Layout from "../layouts/index";
import './Project.css'
import MissionVission from "../components/MissionVission/MissionVission";
import { MainSearchBar } from "../components/intro";
const AboutPage = () => {
    return (
        <React.Fragment>
            <NavBarUpdated2></NavBarUpdated2>
            <Layout>
                <SEO title="Ssebowa – About" />
                <div className="wrapper text-center gallery-connt">
                    <div className="about-back">
                        <div className="mains-searchbar">
                            <MainSearchBar></MainSearchBar>
                        </div>

                        <div>
                            <h1 className="page-titles text-center">About us</h1>
                            <p className="page-headers " style={{ paddingBottom: "400px" }}>
                                Ssebowa changes your searches in to meals for starving children, plants trees where needed most and give sanitary pads to young women
                            </p>
                        </div>
                    </div>


                    <MissionVission className="mission"></MissionVission>

                    <IconBoxContainer classOption="section-pt" />
                    <FunFactContainer classOption="mt-0 mt-lg-0" />

                </div>
                <Footer />
                <ScrollToTop />
                <StickyChatBot />
            </Layout>
        </React.Fragment>
    );
};

export default AboutPage;
