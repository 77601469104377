import { faMagnifyingGlass, faMicrophoneLines, faMicrophoneLinesSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MenuForSearch } from "../../components/menu/main-menu";
import MobileMenu from "../../components/menu/mobile-menu";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { SuggestSpan,VoiceModal } from "../../components/NavBarUpdated/NavBarUpdated2";

function SearchHeader({ query, InputChanged }) {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const history = useHistory();
    const [inputVal, SetInputVal] = React.useState("");
    const [SuggestionReady, SetSuggestionReady] = React.useState(false);
    const [Suggestions, SetSuggestions] = React.useState([]);

    const FetchSuggestions = (value) => {
        let fetchUrl = `https://search.ssebowa.org/suggestion?query=${encodeURI(value)}`;

        console.log("fetching suggestions!!!")
        fetch(fetchUrl)
            .then((response) => response.json())
            .then((response) => {
                var sugg = response;
                SetSuggestionReady(true);
                SetSuggestions(sugg.results);
            })
            .catch((err) => {
                var error = { status: "error", error: err };
                console.error(error);
                SetSuggestionReady(false);
            });
    };

    useEffect(() => {
        if (SuggestionReady) {
            window.addEventListener("click", function (e) {
                if (document.getElementById("suggestBox") && document.getElementById("suggestBox")?.contains(e.target)) {
                } else {
                    SetSuggestionReady(false);
                }
            });
        }
    }, [SuggestionReady]);
    const onCanvasHandler = () => {
        setOffcanvasShow((prev) => !prev);
    };
    const {
        transcript,
        listening,
    } = useSpeechRecognition();

    const RetrieveQuery = () => {
        var h = window.location.href;
        var q = h.split("search?q=")[1];
        if (q === undefined || q === "" || q === " ") {
            window.location.href = "/";
        } else {
            SetInputVal(decodeURIComponent(q));
        }
    };

    useEffect(() => {
        SetInputVal(transcript);
        /*if (!listening) {
            SetInputVal(`${inputVal} ${transcript}`.trim());
        }*/
    }, [transcript]);

    useEffect(() => {
        RetrieveQuery();
    }, []);
    const onChangeInput = (e) => {
        SetInputVal(e.target.value);
        if (e.target.value.replace(/\s/g, "").length) {
            FetchSuggestions(e.target.value);
        } else {
            SetSuggestionReady(false);
            SetSuggestions([]);
        }
        if (inputVal === "") {
            SetSuggestionReady(false);
            SetSuggestions([]);
        }
    };
    const SubmitSearchRequest = (e = false, text = "") => {
        let searchText = text;
        if (text === "") searchText = inputVal;
        if (e) e.preventDefault();
        if (searchText.replace(/\s/g, "").length) {
            history.push("/search?q=" + searchText, { replace: true });
        }
    };
    const SubmitSearchRequestFromVoiceModal = () => {
        if (inputVal.replace(/\s/g, "").length) {
            history.push("/search?q=" + inputVal, { replace: true });
            InputChanged(inputVal);
        }
    };

    return (
        <div className="SearchHeaderMain section position-relative">
            {listening ? <VoiceModal listening={listening} transcript={transcript} SpeechRecognition={SpeechRecognition} /> : <></>}
            <div className="d-flex align-items-center searchMainZIndex searchMainDivContent ">
                <a href={"/"}>
                    <img
                        className="d-block img-fluid SsebowaLogoImage"
                        alt="logo"
                        src="https://res.cloudinary.com/dicgvondb/image/upload/v1674668332/ssebowa/ssebowa.org/search-engine-static-frontend/images/logo/logo_jybeu2.png"
                    />
                </a>
                <div className={"main-Search-Bar-Wrapper"}>
                <form className="d-flex align-items-center  SearchBarMainPageForm" onSubmit={(e) => SubmitSearchRequest(e)}>
                    <input
                        type="search"
                        className="SearchBarMainPage fw-bold"
                        placeholder="Search here..."
                        // value={listening ? `${inputVal} ${transcript}`.trim() : inputVal}
                        value={inputVal}
                        onChange={(e) => onChangeInput(e)}
                    />
                        <button className="SearchBarMainButton no-border border-r-1"
                                onClick={SpeechRecognition.startListening} type="button">
                            <FontAwesomeIcon
                                icon={faMicrophoneLines}
                                className="text-primary"
                                size="lg"
                                style={{
                                    color: "#000",
                                    paddingLeft: 10,
                                }}
                            />
                            
                        </button>
                    <button className="SearchBarMainButton" type="submit">
                        <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            className="text-primary"
                            size="lg"
                            style={{
                                color: "#000",
                                paddingLeft: 10,
                                paddingRight: 10,
                            }}
                        />
                    </button>
                </form>
                {SuggestionReady ? (
                    <div className={"MainSuggestions"}>
                        {Suggestions.length === 0 ?
                        <div style={{width:"100%",marginTop:"-3em",marginBottom:"2em",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}} >No Suggestions</div>
                        :
                        <>
                        {Suggestions.map((item, i) => {
                            return <SuggestSpan name={item} SubmitSearchRequest={SubmitSearchRequest} key={i} />;
                        })}
                    </>
                    }

                    </div>
                ) : (
                    <></>
                )}
                </div>
            </div>
            {/*<div className="ms-auto me-3 searchMainZInde searchMenuRight">
                <MenuForSearch />
            </div>*/}
            <div className="searchMenuRightMobileMenu ms-3 h-100 d-flex align-items-center">
                <button className="toggle d-flex align-items-center flex-column mb-2" onClick={onCanvasHandler}>
                    <span className="icon-top"></span>
                    <span className="icon-middle"></span>
                    <span className="icon-bottom"></span>
                </button>
                <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
            </div>
        </div>
    );
}

export default SearchHeader;

SearchHeader.propTypes = {
    query: PropTypes.string,
    InputChanged: PropTypes.func,
};
