import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBarUpdated2 from "../components/NavBarUpdated/NavBarUpdated2";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import StickyChatBot from "../components/stickyChatBot";
import Footer from "../components/FooterUpdated/Footer";
import Layout from "../layouts/index";
import Uganda from "../../src/assets/images/project/empowerTeengirls.jpg";
import Indonasia from "../../src/assets/images/project/hungryChild.jpg";
import Pakistan from "../../src/assets/images/project/plantTrees.jpg";
import ChildMeal from "../../src/assets/images/project/mealChild.png";
import Girl from "../assets/images/project/childGirl.png";
import "./Project.css";
import { MainSearchBar } from "../components/intro";

const BlogPage = () => {
    return (
        <div>
            <div>

                <NavBarUpdated2></NavBarUpdated2>
                <React.Fragment>
                    <Layout>
                        <SEO title="Ssebowa – Projects" />
                        <div className="wrapper gallery-connt" >
                            <div className="project-back">
                                <div className="mains-searchbar">
                                    <MainSearchBar></MainSearchBar>
                                </div>
                                <h1 className="page-titles text-center">Current Campaings</h1>
                                <p className="page-headers " style={{ paddingBottom: "400px" }}>
                                    Projects around the world
                                </p>
                            </div>


                            <div style={{ paddingTop: "30px" }}>
                                <Container fluid={true}>
                                    <Row>
                                        <div className="text-center">
                                            <h1 className="projects-title">
                                                What <span style={{ color: "#4AB421" }}>Ssebowa</span> Does?
                                            </h1>
                                            <p className="home-para font-normal mt-0">
                                                Your searches make our planet pristine, verdant and comfortable just as it is intended to be.
                                            </p>
                                        </div>

                                    </Row>

                                    <Row className="" style={{ maxWidth: "fit-content", margin: "auto" }}>
                                        <Col className="container">

                                            <div className="project-container justify-items-center justify-content-center mx-auto mt-5">
                                                <div className="container">
                                                    <img style={{ borderRadius: "10%" }} className="img-fluid" src={Uganda} alt="img" />

                                                    <div className="text-center bg-white project-infos">
                                                        <h6 className="infos-ProTilte  mx-auto">Your Searches In
                                                            Uganda</h6>
                                                    </div>
                                                </div>

                                                <div className="container">
                                                    <img style={{ borderRadius: "10%" }} className="img-fluid" src={Indonasia} alt="img" />

                                                    <div className="text-center bg-white project-infos">
                                                        <h6 className="infos-ProTilte  mx-auto">Your Searches In
                                                            Indonasia</h6>
                                                    </div>
                                                </div>

                                                <div className="container">
                                                    <img style={{ borderRadius: "10%" }} className="img-fluid" src={Pakistan} alt="img" />

                                                    <div className="text-center bg-white project-infos">
                                                        <h6 className="infos-ProTilte  mx-auto">Your Searches In
                                                            Pakistan</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="project-cardBox justify-items-center justify-content-center mx-auto mt-5">
                                        <div className="card-margin">
                                            <img
                                                style={{ borderRadius: "10%" }}
                                                className="mg-fluid"
                                                src={Girl}
                                                alt=""
                                            />

                                            <div className="text-center bg-white project-infosCard">
                                                <h6 className="infos-ProTilteScond">Your Searches For A Child Girl</h6>
                                            </div>
                                        </div>

                                        <div className="">
                                            <img style={{ borderRadius: "10%" }} className="img-fluid" src={ChildMeal} alt="Child-Meal" />

                                            <div className="text-center bg-white  project-infosCard">
                                                <h6 className="infos-ProTilteScond">Your Searches For A Meal To A Child</h6>
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                            <Footer />

                            <ScrollToTop />
                            <StickyChatBot />
                        </div>
                    </Layout>
                </React.Fragment>
            </div>
        </div>
    );
};

export default BlogPage;



