import React from "react";
import WhyChooseUs from "../components/WhyChoose.jsx/WhyChooseUs";
import NavBarUpdated2 from "../components/NavBarUpdated/NavBarUpdated2";
import SEO from "../components/seo";
import StickyChatBot from "../components/stickyChatBot";
import VideoCaorusel from "../components/video-carousel";
import AboutContainer from "../containers/about";
import FunFactContainer from "../containers/global/funfact";
import IconBoxContainer from "../containers/global/icon-box";
import IntroContainer from "../containers/home/intro";
import Layout from "../layouts/index";
import RecentBlog from "../components/RecentBlogs/RecentBlog";
import Testimonials from "../components/Testimonials/Testimonials";
import HomeGallery from "../components/HomeGallery/HomeGallery";
import Footer from "../components/FooterUpdated/Footer";
import AboutSsebowa from "../components/Aboutssebowa/AboutSsebowa";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import WhatWeContributeNumbers from "../components/WhatWeContributeNumbers/WhatWeContributeNumbers";

const HomePage = () => {
    const linksArray = [
        { id: 0, navName: "Home", navLink: "/" },
        { id: 1, navName: "About", navLink: "/about" },
        { id: 2, navName: "Gallery", navLink: "/gallery" },
        { id: 3, navName: "Projects", navLink: "/projects" },
        { id: 4, navName: "Contact", navLink: "/contact" },
        { id: 5, navName: "Team", navLink: "/team" },
    ];
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Ssebowa Home" />
                <div className="wrapper">
                    <NavBarUpdated2 HomePage />
                    <IntroContainer />
                    <WhatWeContributeNumbers />
                    <HomeGallery />
                    <HowItWorks />
                    <AboutSsebowa />
                    <WhyChooseUs />
                    <RecentBlog />
                    <Testimonials />
                    <Footer />
                    <IconBoxContainer classOption="section-pb" />
                    <FunFactContainer classOption="mt-10 mt-lg-0" />
                    <AboutContainer />
                    <StickyChatBot />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default HomePage;
