import React from 'react';
import Accordion from './Accordion';
import { useState } from 'react';
import "./Faq.css"

const Faq = () => {
    const accordionData = [
        {
            title: 'What is SSEBOWA?',
            content: `SSEBOWA is an AI charity search engine that gives back to the environment and to people in need with every search. For every query, we’ll plant a tree, provide meals for children and distribute sanitary pads to girls without pads.
            `
        },
        {
            title: 'How do I make a difference with SSEBOWA?',
            content: `By using SSEBOWA, you help us plant trees for a healthier environment, provide meals for children who are sleeping hungry, and distribute sanitary pads to girls who don’t have access to them.`
        },
        {
            title: 'Does SSEBOWA cost anything to use?',
            content: `No, it’s free to use SSEBOWA. All you have to do is search!`
        },
        {
            title: 'How does SSEBOWA fund its charity?',
            content: `SSEBOWA generates ad revenue for each query and uses 💯% of this revenue to make help reforest the world and help those in need.`
        },
        {
            title: 'Who benefits from the searches made through SSEBOWA?',
            content: `The searches made through SSEBOWA help to protect the environment, feed hungry children, and provide sanitary pads to girls without access to these items.`
        },
        {
            title: 'Is there a limit to the number of searches I can do to make an impact?',
            content: `No, there is no limit to the number of searches you can make. The more you search, the more you help.`
        },
        {
            title: 'SSEBOWA have any other features or tools?',
            content: `Yes, SSEBOWA  provides access to a range of tools and services, including email and letter generation, poem, code, song, essay, article creation, as well as the ability to solve scientific equations and perform calculations.`
        },
        {
            title: 'What kinds of emails does SSEBOWA generate?',
            content: `SSEBOWA generates a range of professional-grade emails for various purposes including marketing, recruitment, and personal communication.`
        },
        {
            title: 'Does SSEBOWA generate codes?',
            content: `Yes, SSEBOWA can generate code for both web development and software engineering.`
        },
        {
            title: 'Does SSEBOWA create poems?',
            content: `Yes, SSEBOWA's poetry generator can create unique poetic works based on your keywords and topics that you input.`
        },
        {
            title: 'How can SSEBOWA help with scientific equations?',
            content: `SSEBOWA offers a comprehensive automated equation solver that can be used to solve complex scientific equations in a matter of seconds.`
        },
        {
            title: 'Does SSEBOWA provide calculation services?',
            content: `Yes, SSEBOWA has a powerful calculator that can be used to quickly and accurately perform calculations.`
        },
        {
            title: 'Is SSEBOWA easy to use?',
            content: `Yes, SSEBOWA was designed with user-friendliness in mind and is easy to use for anyone, even those with no technical experience.`
        },
        {
            title: 'Does SSEBOWA track individual searches?',
            content: `No, SSEBOWA does not track individual searches.`
        },
        {
            title: 'Does SSEBOWA accept donations?',
            content: `Yes, we accept donations! You can donate directly at ssebowa.org/donate`
        },
        {
            title: 'What happens if I don’t want to use SSEBOWA?',
            content: `If you don’t want to use SSEBOWA, that’s ok! We encourage you to support causes that are important to you.`
        },
        {
            title: 'Are there any other ways I can help spread the word about SSEBOWA?',
            content: `Yes, please share SSEBOWA with your friends and family, and spread the word about the difference SSEBOWA is making in the world.`
        },
        {
            title: 'What’s the best way to stay up to date with SSEBOWA?',
            content: `The best way to stay up to date with SSEBOWA is to follow us on social media. We post updates on our Facebook, Instagram, LinkedIn and TikTok accounts.`
        },
        {
            title: 'How secure is my privacy on the SSEBOWA?',
            content: `Your privacy is 💯% secure on the SSEBOWA because we do not collect any Data from you.`
        }
    ];
    const [isActive, setIsActive] = useState(false);
    return (
        <div>
            <div className='faqhead p-3'>
                <h1 className='accordion-header'>Planting Seeds of Change with SSEBOWA</h1>
                <p>The internet can be a powerful tool for making meaningful changes in the world. SSEBOWA is the latest search engine aiming to do just that. For every search term, SSEBOWA donates  the ad revenue to plant trees for the environment, provide food for hungry children and distribute sanitary pads for girls who lack access to these essential items.
                    Making an impact through SSEBOWA is simple. All you need to do is search. The more you use SSEBOWA, the greater your impact on the world. Ready to get started?
                </p>
                <p>Here are 20 frequently asked questions about SSEBOWA;</p>
            </div>
            <div>
                <div className="accordion">
                    {accordionData.map(({ title, content }) => (
                        <Accordion key={title} title={title} content={content} ></Accordion>
                    ))}
                </div>
                <div>
                    <div className="accordion-item mt-5">
                        <button className="accordion-title faq-title " onClick={() => setIsActive(!isActive)}>
                            {
                                isActive ?
                                    <>

                                        <div className='accordion-header p-3'><span className='me-4 ms-4' style={{ color: "#2F5DF4" }}>-</span>How can I get in touch with SSEBOWA?</div>
                                    </>
                                    :
                                    <>
                                        <div className='accordion-header faq-titleP p-3' ><span className='me-4 ms-4' style={{ color: "#2F5DF4" }}>+</span>How can I get in touch with SSEBOWA?</div>
                                    </>
                            }
                        </button>
                        {isActive && <div className="accordion-content faq-info p-3 ps-5">You can contact SSEBOWA on our website,l or via email at info@ssebowa.org <br />
                            At SSEBOWA, we’re dedicated to using the power of the internet to bring awareness to important causes and make a meaningful impact on the world. With each search, you’re planting seeds of change and making a difference. Get started with SSEBOWA today!
                        </div>}
                    </div >
                </div>
            </div>
        </div>
    );
};

export default Faq;