import React from 'react';
import Mission from "../../assets/images/miss.PNG";
import Vission from "../../assets/images/viss.PNG";
import Value from "../../assets/images/value.PNG";
import './MissionVission.css';
import Map from '../../assets/images/mapp.png';
import WhatWeContributeNumbers from './WhatWeContributeNumbers';


const MissionVission = () => {
    return (
        <div>
            <div>
                <h1 className='missionHeadings'>Our <span style={{ color: "#3FAF04" }}>Mission</span> And <span style={{ color: "#3FAF04" }}>Vision</span></h1>
            </div>
            <div className='mission-container'>
                <div className='mission-card'>
                    <img src={Mission} alt="" />
                    <h1 className='mission-title'>Our Mission</h1>
                    <p className='mission-pera'>Our mission is to foster a
                        healthier, greener and more
                        equitable society.</p>
                </div>
                <div className='mission-card'>
                    <img src={Vission} alt="" />
                    <h1 className='mission-title'>Our Mission</h1>
                    <p className='mission-pera'>Our vision is to promote a
                        healthier and more equitable
                        world through the strategic
                        reinvestment of profits into
                        climate, soil and humanitarian
                        actions.</p>
                </div>
                <div className='mission-card'>
                    <img src={Value} alt="" />
                    <h1 className='mission-title'>Our Mission</h1>
                    <p className='mission-pera'>We respect and prioritise our
                        people and the communities they
                        come from</p>
                </div>

            </div>


            <div style={{ paddingBottom: "50px", paddingTop: "60px" }}>
                <h1 className='main-title'>Over 171 million trees planted</h1>
                <div className='counter-containers'>

                    <div className='lg-nones count-title'>
                        <p>NOW</p>
                        <WhatWeContributeNumbers></WhatWeContributeNumbers>
                        <div className='line'></div>
                        <p>TREES TOTAL</p>
                    </div>

                    <div className='counter-small-container'>

                        <div className=' count-title mlSmall'>
                            <p className='invisible'>OVER </p>
                            <h1>1.3sec</h1>
                            <div className='lines'></div>
                            <p>TO PLANT A TREE</p>
                        </div>

                        <div className='count-cards count-title'>
                            <p>OVER</p>
                            <h1>20 million</h1>
                            <div className='lines'></div>
                            <p>ACTIVE USERS</p>
                        </div>

                        <div className='small-nones count-cards count-title'>
                            <p>NOW</p>
                            <WhatWeContributeNumbers></WhatWeContributeNumbers>
                            <div className='lines'></div>
                            <p>TREES TOTAL</p>
                        </div>
                    </div>



                    <div className='counter-small-container countertwo'>


                        <div className='mlSmall count-title'>
                            <p>OVER</p>
                            <h1>1234567</h1>
                            <div className='lines'></div>
                            <p>EUR INVESTED</p>
                        </div>

                        <div className='count-title'>
                            <p>OVER</p>
                            <h1>1234567</h1>
                            <div className='lines'></div>
                            <p>PLANTING SITES</p>
                        </div>
                    </div>


                </div>
            </div>

            <div style={{ background: "#008009" }}>
                <div className='image-box'>
                    <h2>We’re planting all around the world</h2>
                    <p>Trees mean a happy environment, healthy people, and a strong economy.</p>
                </div>
                <img className='count-image' src={Map} alt="Map" />
            </div>
        </div >
    );
};

export default MissionVission;