import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SearchResults from "../components/SearchResults/SearchResults";
import SearchResultsImages from "../components/SearchResults/SearchResultsImages";
import SearchResultsVideos from "../components/SearchResults/SearchResultsVideos";
import SEO from "../components/seo";
import Layout from "../layouts/index";
import SearchHeader from "../layouts/SearchHeader";
import ChatBotSuggestions from "../components/ChatBotSuggestions/ChatBotSuggestions";
import AITools from "../components/AITools/AITools";

const SearchPage = () => {
    const [query, SetQuery] = React.useState("");
    const [AiMode,SetAiMode] = useState(null)
    const [searchType, setSearchType] = useState("general");
    const [AiResultReady, setAiResultReady] = useState(false);
    const history = useHistory();

    const SetAiModeOption = (option) => {
        if (option) {
            SetAiMode(option)
        }
        else if (option === null) {
            SetAiMode(option)
        }
    }
    React.useEffect(()=>{
        RetrieveQuery();
    },[window.location.href])
    const RetrieveQuery = () => {
        var h = window.location.href;
        var q = h.split("search?q=")[1];
        if (!q.replace(/\s/g, "").length) {
            history.push("/", { replace: true });
        } else {
            SetQuery(q);
        }
    };
    const InputChanged = (q) => {
        SetQuery(q);
    };

    React.useEffect(() => {
        RetrieveQuery();
    }, []);
    return (
        <React.Fragment style={{ height: "100vh" }}>
            <Layout>
                <SEO title="Ssebowa Search" />
                <meta
                    name="description"
                    content="Ssebowa turns your searches to trees, food for hungry children and sanitary  pads for girls without pads. Search now to mitigate climate change and change lives."
                />
                {AiMode !== null ?
                    <AITools mode={AiMode} SetAiModeOption={SetAiModeOption} />
                    :
                    <></>
                }
                <div className="wrapper">
                    <SearchHeader query={query} InputChanged={InputChanged} />
                    <div className="results-container">
                        <ChatBotSuggestions callAiToolWithMode={SetAiModeOption} />
                        <div>
                            <SearchResults query={query} />
                            <SearchResultsImages query={query}/>
                            <SearchResultsVideos query={query}/>
                        </div>
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default SearchPage;