import React from 'react'
import styles from "./BottomSection.module.css"
import { faPlus, faBookmark, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import Bookmarks from '../Bookmarks/Bookmarks';
import chromeImg from "../../assets/images/chrome.png";
import { ChromeExtensionURL } from '../../connection/BaseUrl';


function BottomSection() {
    const [NewBookMark, SetNewBookMark] = React.useState(false)
    const ToggleNewState = () => {
        SetNewBookMark(!NewBookMark)
    }
    return (
        <div className={styles.BottomSection} >
            <div className={styles.BottomSectionBtnDiv}> 
                <AddToChromeBtn />
                <button className={styles.BottomSectionBtnAddBookmark} onClick={() => ToggleNewState()} >
                    {NewBookMark ?
                        <span style={{ color: "red", }} >Cancel <FontAwesomeIcon
                            icon={faTimes}
                            className="pt-1 pl-2"
                            style={{ color: "red", fontSize: "15px" }}
                        /> </span>
                        :
                        <span>Add Bookmark <FontAwesomeIcon
                            icon={faBookmark}
                            className="pt-1 pl-1"
                            // size="lg"
                            style={{ color: "#fff", fontSize: "15px" }}
                        /> </span>
                    }
                </button>
            </div>
            <div className={styles.BookmarkMainSection}>
                <Bookmarks closeAddNewBookmark={ToggleNewState} addNew={NewBookMark} />
            </div>
        </div>
    )
}

export default BottomSection

export const AddToChromeBtn = ({ style, Navbar }) => {

    const redirectToStore = () => {
        window.open(ChromeExtensionURL, "_blank");
    }
    return (
        <>
            <button onClick={() => redirectToStore()} className={Navbar ? styles.BottomSectionBtnAddToChromeNavBar : styles.BottomSectionBtnAddToChrome} style={style} >
                <img src={chromeImg} alt="Chrome" style={{ width: 35, marginRight: 5 }} />
                <span style={{ fontWeight: "bold" }} >Add to Chrome</span>
            </button>
        </>
    )
}
