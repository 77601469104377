import React, { useEffect, useRef, useState } from "react";
// import InfoBoxes from "./InfoBoxes";
import ResultMain from "./ResultMain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeUp,
  faVolumeXmark,
  faArrowTurnRight,
  faHandPointRight,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import axios from "axios";
import { BASEURL } from "../../connection/BaseUrl";
import { useSpeechSynthesis } from "react-recipes";
import Typewriter from "typewriter-effect";

// import Pagination from "./Pagination";
// import Newapi from "./Newapi";
// import BotResult from "./BotResult";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Grid } from "@mui/material";
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
// import SliderImage from "./SliderImage";
import "./Carousel.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { EffectCoverflow, FreeMode } from "swiper";
import { LoadingSpaceWithRings } from "./SearchResultsVideos";

const styles = {
  container: {
    height: "100%",
    overflowY: "scroll",
    padding: "12px",
    // width: "450px",
  },
};
function SearchResults({ query, onAiResultReady }) {
  const [search_results, SetSearch_Results] = React.useState([]);
  const [fastResults, setFastResults] = React.useState([]);
  const [slowResults, setSlowResults] = React.useState([]);
  const [slowResults2, setSlowResults2] = React.useState([]);
  const [Results_State, SetResults_State] = React.useState(false);
  const [Results_Error, SetResults_Error] = React.useState(false);
  const [chatbotResultTypingDone, setChatbotResultTypingDone] = React.useState(
    false
  );

  const [SsebowaResults, SetSsebowaResults] = React.useState([]);

  const [NewSearch, SetNewSearch] = React.useState([]);

  const [chatbotResultMsgs, setChatbotResultMsgs] = React.useState([]);

  const [currentPage, setcurrentPage] = React.useState(1);
  const [postPerPage, setPostPerPage] = React.useState(5);

  const sliderRef = useRef(null);

  const handlePrev = React.useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = React.useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  // Api Speed ranked
  // in_search
  // search.ssebowa.org
  // backend.ssebowa.org/search
  React.useEffect(() => {
    setFastResults([]);
    SetResults_State(false);
    setSlowResults([]);
    setSlowResults2([]);
  }, [query]);
  const fetchResultsFast = async () => {
    const f_url = `https://search.ssebowa.org/in_search/${query}`;
    try {
      await fetch(f_url, {
        method: "POST",
        mode: "cors",
      })
        .then((r) => r.json())
        .then((r) => {
          // console.log("results fast", r.results);
          setFastResults(r.results);
          SetResults_State(true);
        })
        .catch((e) => {
          console.log("Fetch Error", e);
          SetResults_Error(true);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const FetchSearchQuery = async () => {
    // console.log("Fetching...");
    const f_url = `https://search.ssebowa.org/search/${query}`;
    try {
      await fetch(f_url, {
        method: "POST",
        mode: "cors",
      })
        .then((r) => r.json())
        .then((r) => {
          // console.log("results slow", r.results);
          setSlowResults(r.results);
          // console.log("🚀 ~ file: SearchResults.js:36 ~ .then ~ r.results:", r.results);
          SetResults_State(true);
        })
        .catch((e) => {
          console.log("Fetch Error", e);
          SetResults_Error(true);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const fetchResultSlow2 = async () => {
    // Base Url = https://www.backend.ssebowa.org/
    var f_url = BASEURL + "search";
    try {
      await fetch(f_url, {
        method: "POST",
        mode: "cors",
        headers: {
          query: query,
        },
      })
        .then((r) => r.json())
        .then((r) => {
          const results = r.results;
          const updated = results.map((item) => ({
            Title: item.title,
            link: item.url,
            Description: item.content,
          }));
          SetResults_State(true);
          setSlowResults2(updated);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(async () => {
    if (query) {
      setChatbotResultMsgs([]);
      setChatbotResultTypingDone(false);
      fetchResultsFast();
      FetchSearchQuery();
      fetchResultSlow2();
    }
  }, [query]);

  useEffect(() => {
    SetSearch_Results([...fastResults, ...slowResults, ...slowResults2]);
  }, [fastResults, slowResults, slowResults2]);
  let x;

  useEffect(() => {
    fetch(`https://chatapi.ssebowa.org/in_search/?keyword=${query}`, {
      method: "post",
    })
      .then((response) => response.json())
      .then((data) => SetNewSearch(data))
      .catch((error) => console.error(error));
  }, [query]);
  useEffect(() => {
    cancel();
    async function fetchData() {
      try {
        const response = await axios.get(
          `https://chatapi.ssebowa.org/chatbot/${query}`
        );
        const chatbotResults = response.data.generated_text;
        const msgs = chatbotResults.replaceAll("\\\\", "\n");

        setChatbotResultMsgs(msgs);
      } catch (error) {
        console.log(`Error in fetching data: ${error.message}`);
      }
    }
    fetchData()
    return () => {
      // Clean up function
    };
  }, [query]);
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPage = indexOfLastPost - postPerPage;
  const currentPosts = SsebowaResults.slice(indexOfFirstPage, indexOfLastPost);

  const [ended, setEnded] = useState(false);
  const onBoundary = (event) => {
    console.log(
      `${event.name} boundary reached after ${event.elapsedTime} milliseconds.`
    );
  };
  const onEnd = () => setEnded(true);
  const onError = (event) => {
    console.warn(event);
  };
  const {
    cancel,
    speak,
    speaking,
    supported,
    voices,
    pause,
    resume,
  } = useSpeechSynthesis({
    onEnd,
    onBoundary,
    onError,
  });
  const synth = window.speechSynthesis;
  const voice = synth.getVoices();
  const SpeakNow = (value) => {
    if (!supported) {
      return "Speech is not supported. Upgrade your browser";
    } else {
      speak({ text: value, voice: voices[0] });
    }
  };

  const skipChatbotTypingEffectHandler = () => setChatbotResultTypingDone(true);

  if (Results_Error) {
    return (
      <div
        className="w-100 d-flex align-items-center justify-content-center flex-column"
        style={{ height: "70vh" }}
      >
        <h6 className="text-danger fw-bold mb-5"> An Error Occured </h6>
        <button
          onClick={() => FetchSearchQuery()}
          type="button"
          className="btn btn-primary"
        >
          Retry
        </button>
      </div>
    );
  }

  /*return (
        <>
            {/!*<div className="w-100 d-flex align-items-center justify-content-center" style={{ height: "70vh" }}>
                   <ColorRing
                       visible={true}
                       height="80"
                       width="80"
                       ariaLabel="blocks-loading"
                       wrapperStyle={{}}
                       wrapperClass="blocks-wrapper"
                       colors={["#59e3a7", "#118442", "#181C51", "#59e3a7", "#118442"]}
                   />
               </div>*!/}
        </>
    )*/

  return (
    <div>
      <div className="SearchResultsMain">
        <div className="SearchResultsInnerLeft ">
          {/*<p className="text-dark mb-0">
                            About {search_results != undefined ? <>{Math.round(Math.random() * 27300000000)}</> : <> {search_results?.number_of_results}</>}{" "}
                            results
                        </p>*/}

          <div className="chatbot-result">
            <p className="chatbot-query">
              <FontAwesomeIcon icon={faHandPointRight} className="query-icon" />
              <span className="query-text">{decodeURI(query)}</span>
            </p>
            {chatbotResultMsgs.length < 1 && (
              <div className="chatbot-result-row">
                <span className="chatbot-result-text">Thinking...</span>
              </div>
            )}
            {!!chatbotResultMsgs.length && (
              <>
                <div className="chatbot-result-row">
                  {chatbotResultTypingDone ? (
                    <span className="chatbot-result-text">
                      {chatbotResultMsgs}
                    </span>
                  ) : (
                    <Typewriter
                      options={{
                        strings: chatbotResultMsgs,
                        delay: 40,
                      }}
                      onInit={(typewriter) => {
                        typewriter
                          .typeString(chatbotResultMsgs)
                          .start()
                          .callFunction(() => setChatbotResultTypingDone(true));
                      }}
                    />
                  )}
                </div>
                <div className="chatbot-result-actions">
                  {!chatbotResultTypingDone && (
                    <button
                      className="stop-typing-animation-btn"
                      onClick={skipChatbotTypingEffectHandler}
                    >
                      <FontAwesomeIcon
                        icon={faArrowTurnRight}
                        style={{ marginRight: 5 }}
                      />
                      {"Skip to content"}
                    </button>
                  )}
                  {speaking ? (
                    <button className="Chatbot-Voice-btn" onClick={cancel}>
                      <FontAwesomeIcon
                        icon={faVolumeXmark}
                        style={{ marginRight: 5 }}
                      />
                      {"Stop"}
                    </button>
                  ) : (
                    <button
                      className="Chatbot-Voice-btn"
                      onClick={() => SpeakNow(chatbotResultMsgs)}
                    >
                      <FontAwesomeIcon
                        icon={faVolumeUp}
                        style={{ marginRight: 5 }}
                      />
                      {"Listen"}
                    </button>
                  )}
                </div>
              </>
            )}
          </div>

          {search_results && (
            <div style={{ marginTop: "2rem" }}>
              {/*<h5 className="px-2 px-lg-4 mb-2 mb-lg-4 text-center font-weight-bold">
                                    <FontAwesomeIcon icon={faCaretRight} className="pe-2 text-primary" />
                                    General Results
                                    <FontAwesomeIcon icon={faCaretLeft} className="ps-2 text-primary" />
                                </h5>*/}
              <div className="result-slider">
                <div style={{ margin: "0 -15px" }}>
                  <Swiper
                    ref={sliderRef}
                    // effect={"coverflow"}
                    // initialSlide={2}
                    // loop={true}
                    // loopedSlides={2}
                    height={190}
                    grabCursor={true}
                    cssMode={false}
                    // centeredSlides={true}
                    freeMode={{ sticky: false, enabled: true }}
                    spaceBetween={15}
                    slidesPerView={"auto"}
                    breakpoints={{
                      300: {
                        /*coverflowEffect: {
                                                    stretch: 100,
                                                },*/
                        // initialSlide: 0,
                        cssMode: true,
                      },
                      700: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                        /*coverflowEffect: {
                                                    stretch: -5,
                                                },*/
                        height: 250,
                      },
                    }}
                    /*coverflowEffect={{
                                            rotate: 5,
                                            stretch: 20.5,
                                            depth: 150,
                                            modifier: 3,
                                            slideShadows: false,
                                        }}*/
                    pagination={false}
                    modules={[FreeMode]}
                    className="mySwiper"
                  >
                    {Results_State ? (
                      search_results?.map((item, i) => {
                        return (
                          <SwiperSlide key={i}>
                            <ResultMain key={i} data={item} />
                          </SwiperSlide>
                        );
                      })
                    ) : (
                      <LoadingSpaceWithRings />
                    )}
                  </Swiper>
                  {/*<button className="prev-arrow" onClick={handlePrev}>
                                            <FontAwesomeIcon icon={faCaretLeft} className={`icon`} />
                                        </button>
                                        <button className="next-arrow" onClick={handleNext}>
                                            <FontAwesomeIcon icon={faCaretRight} className={`icon`} />
                                        </button>*/}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchResults;

SearchResults.propTypes = {
  query: PropTypes.string,
};
