import React, { useState } from 'react';
import './Faq.css'

const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="accordion-item">
            <button className="accordion-title faq-title " onClick={() => setIsActive(!isActive)}>
                {
                    isActive ?
                        <>

                            <div className='accordion-header p-3'><span className='me-4 ms-4' style={{ color: "#2F5DF4" }}>-</span>{title}</div>
                            {/* <div className="accordion-content ">{content}</div> */}
                        </>
                        :
                        <>
                            {/* <div className='accordion-header'>
                                +
                            </div> */}
                            <div className='accordion-header faq-titleP p-3' ><span className='me-4 ms-4' style={{ color: "#2F5DF4" }}>+</span>{title}</div>
                        </>
                }
                {/* <div className='accordion-header'>{title}</div> */}
                {/* <div>{isActive ? '-' : '+'}</div> */}
            </button>
            {isActive && <div className="accordion-content faq-info p-3 ps-5">{content}</div>}
        </div >
    );
};

export default Accordion;